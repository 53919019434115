import React from "react"

const Carolyn = () => {
  return (
    <div className="carolyn-svg">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="247.25"
        height="98.21"
        data-name="Layer 1"
        viewBox="0 0 247.25 98.21"
      >
        <path
          className="carolyn-svg-first"
          fill="none"
          stroke="#231f20"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M25.6 45.03c7.53-12.31 10.61-27.28 8.55-41.57C34 2.41 33.58 1.11 32.52 1c-.42-.04-.82.12-1.2.3-9.13 4.18-14.97 13.19-19.7 22.04C5.92 34 1 45.53 1 57.61c0 3.41.43 6.92 2.11 9.88s4.83 5.27 8.24 5.16c3.29-.11 6.12-2.35 8.25-4.86s3.84-5.39 6.31-7.57 6.05-3.54 9.07-2.25c-.9-1.32-2.94-.18-4.01 1-1.41 1.55-2.83 3.21-3.38 5.23s.04 4.52 1.88 5.52c2.34 1.27 5.38-.58 6.36-3.05s.5-5.25.01-7.86c.26 2.18.57 4.48 1.93 6.2s4.16 2.51 5.77 1.02c1.24-1.15 1.29-3.07 1.95-4.63s2.94-2.77 3.93-1.4c.71.99.02 2.35-.01 3.58-.04 1.44 1.02 2.83 2.42 3.17s2.98-.39 3.62-1.68c.34-.68.42-1.46.75-2.15 1.26-2.62 4.99-2.45 7.84-1.88-1.62.15-3.27-.13-4.88.13s-3.29 1.27-3.56 2.88c-.25 1.49.85 2.95 2.22 3.59s2.94.64 4.45.56c.6-.03 1.22-.08 1.74-.37 1.19-.66 1.41-2.36.85-3.6s-1.68-2.1-2.76-2.92c1.72-.37 3.59 1.14 3.59 2.9 0 .64-.2 1.32.08 1.89.48.99 2.07.87 2.78.03s.79-2.03.84-3.13c.3-7.18.59-14.45-.78-21.51-1.48-7.6-4.87-14.96-4.51-22.69.8-.48 1.89-.09 2.49.61s.84 1.65 1.05 2.55c2.43 10.45 3.71 21.18 3.8 31.91.02 2.13 0 4.32.71 6.32s2.34 3.84 4.45 4.14 4.46-1.5 4.18-3.61c-.7 1.1.51 2.66 1.81 2.6s2.31-1.24 2.65-2.5c3.44 7.02 6.37 14.3 8.75 21.75-3.49 1-7.56-.61-9.43-3.72s-1.38-7.46 1.15-10.07 6.84-3.27 10.02-1.51c1.46.8 2.81 2.09 4.48 2.04 1.47-.05 2.66-1.12 3.9-1.9 2.54-1.59 5.77-2.01 8.64-1.14"
          data-name="carolyn &amp;lt;Path&amp;gt;"
        ></path>
        <path
          className="carolyn-svg-last"
          fill="none"
          stroke="#231f20"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M155.17 16.24c.97-3.14 1.02-6.66-.39-9.63s-4.42-5.26-7.71-5.31c-3.24-.05-6.15 1.93-8.56 4.1-3.11 2.8-5.82 6.04-8.01 9.61-1.02 1.65-1.95 3.46-1.97 5.4-.03 2.71 1.81 5.21 4.2 6.51s5.21 1.54 7.91 1.27c-3.6 0-6.62 2.71-8.74 5.61-3.28 4.5-5.33 9.89-5.85 15.44-.18 1.91-.16 3.93.74 5.62 1.05 1.95 3.1 3.14 5.17 3.93 5.63 2.17 13.06 1.58 16.38-3.46-1.34 1-2.64 2.31-2.82 3.97s1.42 3.5 3 2.95c-2.03 2.23-3.25 5.2-3.37 8.21.36-2.22.77-4.56 2.17-6.32s4.16-2.66 5.96-1.31c1.37 1.02 2.56 3.23 4.09 2.46.79-.4 1.01-1.43 1.63-2.06.87-.88 2.34-.8 3.49-.33s2.17 1.24 3.36 1.59c3.76 1.09 7.19-2.3 9.64-5.35.38 4.11 3.76 7.39 7.48 9.17 3.72 1.78 7.87 2.44 11.81 3.69 8.61 2.74 16.24 8.49 21.24 16.03.72 1.08 1.36 2.58.52 3.57-.37.44-.95.64-1.5.81-15.1 4.8-31.32 6.01-46.97 3.51-1.48-.24-3.06-.56-4.09-1.66-1.27-1.35-1.28-3.45-.96-5.28.97-5.52 4.21-10.45 8.29-14.29 4.09-3.83 9-6.67 13.98-9.24a177.144 177.144 0 0160.94-18.48"
          data-name="lastname &amp;lt;Path&amp;gt;"
        ></path>
      </svg>
    </div>
  )
}

export default Carolyn
