import React from "react"

const DavidLegg = () => {
  return (
    <div id="david-trigger" className="david-sign">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="295.35"
        height="69.53"
        data-name="Layer 1"
        viewBox="0 0 295.35 69.53"
      >
        <path
          className="david"
          fill="none"
          stroke="#231f20"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M121.06 24.99c-.15.92-.56 1.8-1.17 2.5m39.06 33.01c-2.74.17-5.91.18-7.71-1.9-1.22-1.41-1.45-3.41-1.6-5.27-.96-11.84-1.26-23.74-.91-35.61.11-3.68.25-7.53-1.29-10.88 1.05 9.36.2 18.93-2.48 27.95-.22.73-.46 1.48-.74 2.21-.79 2.05-1.91 3.97-3.74 5.12-2.48 1.56-6.55.72-7.19-2.14-.51-2.3 1.49-4.56 3.77-5.18s8.21 1.32 5.95.63c-8.14-2.48-8.12.53-9.72 3.05-1.95 3.1-6.87 4.55-10.54 4.53-.86 0-1.77-.11-2.47-.62-2.31-1.66-.47-5.41-1.36-8.12.39 1.68-.97 3.38-2.59 3.97s-3.42.37-5.13.14c-.92-.12-1.9-.28-2.56-.93s-.69-1.97.16-2.35c-.11 1.9-.24 3.86-1.01 5.6s-2.34 3.26-4.24 3.42a35.52 35.52 0 00-3.03-9.7c.95 2.13-.34 4.74-2.31 6s-4.41 1.47-6.75 1.54c-1.79.05-4.04-.27-4.6-1.98-.5-1.53.73-3.41-.19-4.74.44 2.99-2.46 5.38-5.23 6.59-1.34.59-2.79 1.07-4.24.91s-2.91-1.13-3.22-2.56c-.46-2.08 1.55-3.88 3.52-4.66 2.6-1.03 5.54-1.17 8.23-.4-1.3-.44-2.73-.21-4.08.04A441.736 441.736 0 0032.6 47.03c-2.61.79-5.3 1.66-7.36 3.45-1.13.98-2.06 2.3-2.21 3.79-.19 1.99 1.09 3.91 2.78 4.99s3.72 1.47 5.7 1.72c4.3.55 8.68.55 12.98 0 2.72-.34 5.46-.92 7.89-2.18 2.74-1.43 4.98-3.68 6.7-6.25 3.69-5.5 5.01-12.46 3.92-19s-4.52-12.59-9.33-17.15c-2.21-2.09-4.73-3.9-7.58-4.96-3.47-1.3-7.24-1.45-10.94-1.59-8.95-.33-18.12-.63-26.65 2.09-1.71.54-3.42 1.23-4.74 2.43-3.37 3.08-3.31 8.49-1.65 12.7 3.62 9.17 14.05 15.1 23.78 13.52"
        ></path>
        <path
          className="stick"
          fill="none"
          stroke="#231f20"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M36.36 1c.07 19.73-3.55 34.55-1.77 55.45"
          data-name="david-drop&amp;lt;Path&amp;gt;"
        ></path>
        <path
          className="l"
          fill="none"
          stroke="#231f20"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M179.51 23.06c-2.32 3.16-3.23 7.33-2.42 11.17s3.31 7.3 6.71 9.26c5.24 3.03 11.77 2.37 17.72 1.26 3.82-.71 7.83-1.69 10.57-4.44 2.71-2.73 3.69-6.75 3.83-10.6.3-8.15-2.77-16.37-8.33-22.33-.59-.63-1.25-1.26-2.08-1.52-1.42-.43-2.93.36-4.04 1.34-4.7 4.17-5.05 11.27-5.05 17.56v23.57c0 2.24-.03 4.61-1.18 6.53-1.18 1.99-3.4 3.18-5.67 3.65s-4.61.3-6.92.14c-1.74-.13-4.05-.88-3.86-2.62.11-1.02 1.12-1.68 2.08-2.04 1.86-.7 3.89-.8 5.88-.78 12.71.12 25.13 4.85 37.83 4.27 7.48-.34 14.87-2.56 21.3-6.41"
          data-name="L&amp;lt;Path&amp;gt;"
        ></path>
        <path
          className="egg"
          fill="none"
          stroke="#231f20"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M221.76 40.92c1.8-.05 3.59-.59 5.11-1.54 1.71-1.07 3.13-3.43 1.86-5-.57-.71-1.55-.99-2.46-.95-2.95.13-5.14 3.76-3.87 6.43 1.16 2.45 4.18 3.33 6.88 3.53 3.3.24 7.12-.43 8.82-3.27.89-1.49 1.19-3.53 2.75-4.3.75-.37 1.63-.34 2.47-.3 2.4.12 4.8.23 7.2.35-2.15-.46-4.35-.64-6.55-.54-1.43.07-3.1.43-3.69 1.74-.47 1.03-.02 2.31.85 3.04s2.05.97 3.18.93c3.45-.11 6.64-2.85 7.28-6.24 2.3 5.46 1.71 11.68.7 17.53-.73 4.19-1.72 8.51-4.35 11.86-2.63 3.34-7.34 5.41-11.26 3.77-.7-.29-1.38-.71-1.82-1.33-1.06-1.48-.49-3.55.29-5.2 2.92-6.12 8.18-10.87 14.02-14.31 5.84-3.44 12.3-5.68 18.7-7.9-1.06-.02-1.58-1.5-1.06-2.43s1.63-1.34 2.67-1.55c1.76-.35 3.6-.3 5.33.14-1.92-.24-3.97-.47-5.71.38s-2.87 3.21-1.75 4.79c1.07 1.49 3.5 1.32 4.9.14s2.03-3.03 2.58-4.78a62.659 62.659 0 012.47 20.33c-.16 3.77-1.02 8.08-4.33 9.9-2.4 1.32-5.34.89-8.04.42-1.48-.26-3.06-.57-4.1-1.65-1.24-1.28-1.34-3.36-.7-5.02s1.91-3 3.18-4.26a81.965 81.965 0 0131.05-19.1"
          data-name="egg&amp;lt;Path&amp;gt;"
        ></path>
      </svg>
    </div>
  )
}

export default DavidLegg
